import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectOpenCasesDetailsPage from "./selectors";
import reducer from "./reducer";
import CaseCard from "./CaseCard";
import { makeSelectCardCases } from "../OpenCases/selectors";
import { getClaimcountNumber } from "./selectors";
import { getUrlParameter } from "../../constants/commonFunction";
import { ApiDispatchContext } from "../../context/ApiContext";
import { casesUpdate } from "../OpenCases/actions";
import { claimnumberUpdate } from "./actions";
import ErrorAlert from "../../components/ErrorAlert";
import CardTabs from "../../components/CardTabs";
import CommonNavbar from "../../components/CommonNavbar/index";
import path from "../../constants/pathConstants";
import './index.css';
import PropTypes from "prop-types";
import { push } from 'connected-react-router';
import AcceptButtons from "./AcceptButtons/index";
import { getEmployeeRole } from '../App/selectors';
import {cardDataVendor,cardDataInvstigator} from "../../constants/openCasesTabConstants";
import { useNavigate } from "react-router-dom";

export function OpenCasesDetailsPage({ allCases, updateCase , employeeRole, redirectPath, Updateclaimnumber, getClaimcount}) {
  useInjectReducer({ key: "openCasesDetailsPage", reducer });
  const [selectedValue, setSelectedValue] = useState();
  const [tabList, setTabList] = useState(employeeRole === "vendor" ? cardDataVendor : cardDataInvstigator);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const case_id = Number(getUrlParameter('case'));

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (allCases) {
      selectedCard(allCases);
    } else {
      let param = {
        "caseType": "open",
      }
      api.getCasesDetails(param).then(data => {
        if (data.data && data.data.success) {
          updateCase(data.data.data.rows)
          selectedCard(data.data.data.rows)
          Updateclaimnumber('1');
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
    }
  }, []);

  const selectedCard = useCallback((cards) => {
    cards.forEach(element => {
      if (element[1].value === case_id) { // it is expected that on 0th index case details will be placed
        setSelectedValue([element]);
        const lobType = element.find(item => item.label === "LOB")?.value || "";
        if(lobType === "A&H") {
          const allTabs = [...tabList];
          allTabs.splice(4, 3);
          setTabList(allTabs);
        }
      }
    });
  }, [case_id, tabList]);

  const redirectAssignCase = () => {
    navigate(path.ASSIGN_INVESTIGATOR + "?"+btoa("case=" + [case_id]));
  }

  const doNotAcceptCase = () => {
    //event of gtm when user click don't accept the case
    window.dataLayer.push({
      event: "case_rejected",
    });
    navigate(path.DO_NOT_ACCEPT_CASE + "?"+ btoa("case=" + [case_id]));
  }
  
  return (
    <div className="open-clases-details-page">
      <CommonNavbar title="Open Cases" search={false} download={false}  backButton={path.OPEN_CASE} navbarname='AssignedBlocksOpenCases' />
      <div className="OpencasesDetails-main">
      <CaseCard selectCards={selectedValue} />
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      {employeeRole === "vendor" ? (
      <CardTabs tabData={tabList} getClaimcount={getClaimcount}/>
      ) : <CardTabs tabData={tabList} getClaimcount={getClaimcount}/>}
      {employeeRole === "vendor" ? (
        <div className="AcceptButtons-ContainerParent">
          <div className="AcceptButtons-Container">
            <AcceptButtons background="#E42125" datatestid="save-button" onClick={()=>doNotAcceptCase()}>Do Not Accept</AcceptButtons>
            <AcceptButtons background="#00AF00" datatestid="save-button-2" onClick={()=>redirectAssignCase()}>Accept</AcceptButtons>
          </div>
        </div>
      ) : null}
    </div>
  );
}

OpenCasesDetailsPage.propTypes = {
  dispatch: PropTypes.func,
  employeeRole: PropTypes.string,
  getClaimcount : PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  openCasesDetailsPage: makeSelectOpenCasesDetailsPage(),
  allCases: makeSelectCardCases(),
  employeeRole: getEmployeeRole(),
  getClaimcount: getClaimcountNumber()
});

export function mapDispatchToProps(dispatch) {
  return {
    updateCase: (data) => dispatch(casesUpdate(data)),
    Updateclaimnumber: (data) => dispatch(claimnumberUpdate(data)),
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(OpenCasesDetailsPage);